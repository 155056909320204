// 这个文件主要是对axios进行二次封装
// 因为原本的axios不能满足我们的功能需求
// 最终要达到
// 1、配置基础路径和超时限制
// 2、添加进度条信息  nprogress
// 3、返回的响应不再需要从data属性当中拿数据，而是响应就是我们要的数据
// 4、统一处理请求错误, 具体请求也可以选择处理或不处理

import axios from "axios";
import Nprogress from "nprogress"; //引入js
import "nprogress/nprogress.css"; //引入css

// 1、配置基础路径和超时限制
const instance = axios.create({
  baseURL: "https://crm.bodorme.com/", //公共的基础路径
  timeout: 20000,
});

// 2、添加进度条信息  nprogress（开启进度条）
instance.interceptors.request.use(
  // 请求拦截器的成功
  (config) => {
    Nprogress.start();
    return config;
  }
);

// 3、返回的响应不再需要从data属性当中拿数据，而是响应就是我们要的数据
instance.interceptors.response.use(
  (response) => {
    Nprogress.done();
    return response.data;
  },
  (error) => {
    // 4、统一处理请求错误, 具体请求也可以选择处理或不处理
    Nprogress.done();
    alert("ajax请求失败");
    return Promise.reject(error); //让错误信息继续传递下去
    // return new Promise(() => {}) //返回pending状态的promise，可以中断promise链
  }
);

export default instance;
